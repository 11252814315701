
import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Calendar from '../bundles/Roostify/components/Calendar'

document.addEventListener('DOMContentLoaded', () => {
  if (!document.getElementById('react_calendar')) return null;
  let config = {
    loanApplicationId: RoostifyVariables.token
  };

  ReactDOM.render(
    <Calendar name="Calendar" config={config}  />,
    document.getElementById('react_calendar'));
})
