import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'moment';

export default class CalendarDailyTasks extends React.Component {

  constructor(props) {
    super(props);
  }

  openModal(taskId) {
    document.getElementById(`#task_modal_${taskId}`).modal();
  }

  backtoCal(day) {
    document.getElementById(`day-${day.dayIndex}`).focus();
  }

  taskTitle(date) {
    if (Moment(date).isBefore(Moment(), 'day')) {
      return 'Overdue Tasks';
    }
    if (Moment(date).isAfter(Moment(), 'day')) {
      return 'Upcoming Tasks';
    }
    return 'Tasks Due';
  }

  hasTasks(selectedDay) {
    return (selectedDay.tasks) ? !!selectedDay.tasks.length : false
  }

  isTabPanelTabbable(selectedDay) {
    return (selectedDay.tasks && selectedDay.tasks.length) ? 0 : -1
  }

  render() {
    return (
      <div role="tabpanel" tabIndex={this.isTabPanelTabbable(this.props.selectedDay)} aria-expanded={this.hasTasks(this.props.selectedDay)} aria-describedby={`day-${this.props.selectedDay.dayIndex}`} id={`daily-task-list-${this.props.selectedDay.dayIndex}`} className={`daily-task-list`}>
        <div className="row c-calendar-task__list-item c-calendar-task__list-item--top">
          <div className="col-md-3">
            <div className={`row c-calendar-task__selected-date row ${(this.props.dayViewShown && this.props.selectedDay.tasks.length ? 'show' : 'hide')}`}>
              <span className="c-calendar-task__selected-badge"></span>
              <span className="c-calendar-task__selected-text" aria-hidden="true">
                {Moment(this.props.selectedDay.date).format('D MMM')}
              </span>
            </div>
          </div>
          <div className={`c-calendar-task__wrap ${(this.props.dayViewShown && this.props.selectedDay.tasks.length ? 'show' : 'hide')}`}>
            <h3 aria-label={`${this.taskTitle(this.props.selectedDay.date)} for ${Moment(this.props.selectedDay.date).format('MMMM Do')}`} tabIndex="1" className="js-link-dailytask"><span aria-hidden="true">{this.taskTitle(this.props.selectedDay.date)}</span></h3>
          </div>
        </div>
        {/*
          dangerouslySetInnerHTML has to be this way until modals are converted to react
          which is out of scope for this calendar project
        */}
        <ol aria-label={`List of tasks for ${Moment(this.props.selectedDay.date).format('MMMM Do')}`} className="c-calendar-task__list" dangerouslySetInnerHTML={{__html: this.props.taskModals}}></ol>
        <a href="#" className="sr-only" onClick={() => this.backtoCal(this.props.selectedDay)}>back</a>
      </div>
    );
  }
}
