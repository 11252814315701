import React from 'react';
import Moment from 'moment';
import uuid from 'react-uuid'
const DailyTasksIcon = (props) => {
  if (!props.hasTasks(props.tasks)) { return false; }
  const iconTitles = {
    overdue: 'Tasks overdue',
    today: 'Tasks due today',
    upcoming: 'Upcoming tasks'
  },
  classNames = {
    overdue: 'c-calendar__date-badge c-calendar__date-badge--overdue',
    upcoming: 'c-calendar__date-badge c-calendar__date-badge--upcoming c-color__primary'
  },
  isSameOrAfter = Moment(props.date).isSameOrAfter(Moment(), 'day'),
  isSame = Moment(props.date).isSame(Moment(), 'day'),
  className = ((isSameOrAfter && classNames.upcoming) || classNames.overdue ),
  iconTitle = (isSameOrAfter ? ((isSame && iconTitles.today) || iconTitles.upcoming) : iconTitles.overdue),
  icon = ((isSameOrAfter && props.asteriskIcon) || props.bellIcon );

  return (<div dangerouslySetInnerHTML={{ __html: icon }} title={iconTitle} className={className}></div>);
};

const AriaDaySelected = (props) => {
    if (props.isToday(props.day.date)) {
      return (<span className="sr-only">, current date</span>);
    }
    return "";
}

export default class CalendarDay extends React.Component {

  constructor(props) {
    super(props);
  }

  isToday(date) {
    return Moment().isSame(date, 'day');
  }

  hasTasks(tasks) {
    return tasks.length;
  }

  hasTasksToday = (tasks, date) => {
    return (this.hasTasks(tasks) && this.isToday(date));
  }

  dayClasses = (selectedDay, day) => {
    return (Moment(selectedDay).isSame(day) ? 'c-calendar__date-text--is-selected' : 'day-' + (new Date).getTime());
  }

  ariaControls = (day) => {
    return (this.hasTasks(day.tasks) && !!day.date) ? `daily-task-list-${day.dayIndex}` : '';
  }

  handleClick (e, day) {
    e.preventDefault()
    this.props.showDayView(day)
  }

  handleKeyup (e, tab) {
    e.preventDefault()
    if (e.which === 13 || e.which === 32) {
      return false
    }
    if (e.which === 37) this.props.previousTab(tab)
    else if (e.which === 39) this.props.nextTab(tab)
  }

  render() {
    return (
      <li
        role="tab"
        aria-hidden={(!this.hasTasks(this.props.day.tasks))}
        onClick={(e) => this.handleClick(e, this.props.day)}
        className="c-calendar__month-cell c-calendar__month-day brand brand--lg"
      >
        <button
          onKeyUp={e => this.handleKeyup(e, this)}
          aria-selected={Moment(this.props.selectedDay.date).isSame(this.props.day.date)}
          aria-controls={this.ariaControls(this.props.day)}
          aria-label={`${this.props.day.tasks.length} tasks for ${Moment(this.props.day.date).format('dddd, MMM Do')}`}
          id={(this.props.day.dayIndex > -1) ? `day-${this.props.day.dayIndex}` : `day-${uuid()}`}
          tabIndex={(Moment(this.props.selectedDay.date).isSame(this.props.day.date)) ? 0 : -1}
          href="#"
          className={`c-calendar__date ${( this.isToday(this.props.day.date) ? 'c-calendar__date--today' : '')} ${( this.hasTasks(this.props.day.tasks) ? 'c-calendar__date--tasks' : '')}`}
        >
          <span className={`c-calendar__date-text ${this.dayClasses(this.props.selectedDay.date, this.props.day.date)}`}>
            <span className="sr-only">{(Moment(this.props.day.date).isValid()) ? Moment(this.props.day.date).format('dddd, MMMM ') : '' }</span> {this.props.day.dayIndex > -1 ? this.props.day.dayIndex + 1 : '' }
          </span>
          <AriaDaySelected day={this.props.day} isToday={this.isToday} />
          <DailyTasksIcon bellIcon={this.props.bellIcon} asteriskIcon={this.props.asteriskIcon} hasTasks={this.hasTasks} hasTasksToday={this.hasTasksToday} tasks={this.props.day.tasks} date={this.props.day.date} />
        </button>
      </li>
    );
  }
}
