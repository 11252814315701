import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'moment';
import CalendarDay from './CalendarDay';

export default class CalendarMonth extends React.Component {

  constructor(props) {
    super(props);
  }

  tabs () {
    return (this.daysOfMonthArray).filter(day => day.tasks.length);
  }

  previousTab (tab) {
    const index = this.tabs().indexOf(this.selectedDay)
    if (index > 0) {
      this.showDayView(this.tabs()[index - 1])
      document.getElementById(`day-${index}`).focus();
    }
  }

  nextTab (tab) {
    const index = this.tabs().indexOf(this.selectedDay)
    if (index < this.tabs().length - 1) {
      this.showDayView(this.tabs()[index + 1])
      document.getElementById(`day-${index + 2}`).focus();
    }
  }

  render() {

    return (
      <div>
        <div className="c-calendar__weekdays c-calendar__days">
          {this.props.daysOfTheWeekAbbr.map((day, i) =>
            <h2 className="c-calendar__month-cell c-calendar__day" key={i}>{day}</h2>
          )}
        </div>
        <ul className="c-calendar__monthly" role="tablist" aria-label="Days of the month tasks tabs">
          {this.props.daysOfMonthArray.map((day, index) =>
            <CalendarDay
              previousTab={this.previousTab}
              nextTab={this.nextTab}
              tabs={this.tabs}
              daysOfMonthArray={this.props.daysOfMonthArray}
              handleKeyup={this.handleKeyup}
              bellIcon={this.props.bellIcon}
              asteriskIcon={this.props.asteriskIcon}
              day={day}
              index={index}
              showDayView={this.props.showDayView}
              key={index}
              selectedDay={this.props.selectedDay}
            />
          )}
        </ul>
      </div>
    );
  }
}
