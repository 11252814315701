import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'moment-timezone';
import CalendarMonth from './CalendarMonth';
import CalendarDailyTasks from './CalendarDailyTasks';

const TASKS_API = "/api/draft/document_requests?loan_application_id";
const DAYS_OF_THE_WEEK = Moment.weekdays(); // [Sunday, Monday...] This is localizable and internationalizable
const calendarNavigationCssClasses = "c-calendar__arrow btn btn--disguised"
const CalendarNavigation = (props) => {
  return (
    <div className="row c-calendar__month">
      <a href="" aria-label="Previous Months Tasks" dangerouslySetInnerHTML={{ __html: props.arrowLeftIcon }} className={calendarNavigationCssClasses} onClick={props.prevMonth}>
      </a>
      <h2>{props.currentMonth.format('MMMM YYYY')}</h2>
      <a href="" aria-label="Next Months Tasks" dangerouslySetInnerHTML={{ __html: props.arrowRightIcon }} className={calendarNavigationCssClasses} onClick={props.nextMonth}>
      </a>
    </div>
  );
}

export default class Calendar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedDay: {date: null, dayIndex: null},
      daysOfTheWeekAbbr: DAYS_OF_THE_WEEK.map(d => d.charAt(0)),
      currentMonth: Moment(),
      loanApplicationId: this.props.config.loanApplicationId,
      daysOfMonthArray: this.setMonth(),
      tasksThisMonth: [],
      tasks: [],
      todaysTasks: [],
      taskModals: null,
      dayViewShown: false,
      dayViewId: null,
      iconMap: RoostifyVariables.icon_map,
    };
  }

  setMonth(currentMonth = Moment().format()) {
    //Builds the monthly Calendar
    let firstDayOfMonth = Moment(currentMonth).startOf('month').clone();
    let numDaysInMonth = firstDayOfMonth.daysInMonth();
    let pastMonthDaysArray = (firstDayOfMonth.day() > 0) ? [...Array(firstDayOfMonth.day()).keys()].map( (index) => ({ dayIndex: -1, date: null, tasks: [] }) ) : [];
    let futureDaysArray = (numDaysInMonth + firstDayOfMonth.day() < 35) ? [...Array(35 - (numDaysInMonth + firstDayOfMonth.day())).keys()].map( (index) => ({ dayIndex: -1, date: null, tasks: [] }) ) : [];
    let monthDaysArray = [...Array(numDaysInMonth).keys()].map((dayIndex) => {

        let obj = {
          dayIndex: dayIndex,
          date: Moment(firstDayOfMonth).add(dayIndex, 'days').format(),
          tasks: [],
        };
        return obj;
      });

    return [...pastMonthDaysArray, ...monthDaysArray, ...futureDaysArray];
  }

  setTasksToDaysInMonth(daysOfMonthArray, currentMonth, tasks) {
    const tzName = RoostifyVariables.time_zone_name;

    tasks.filter(task => {
        return Moment(currentMonth).isSame(Moment.tz(task.due_at, tzName), 'month')})
      .forEach( task => {
        const foundIndex = daysOfMonthArray.findIndex(d => d.dayIndex === (Moment.tz(task.due_at, tzName).date() - 1));
        daysOfMonthArray[foundIndex].tasks.push(task);
      }
    );
    const firstTasksOfMonth = daysOfMonthArray.find(day => day.tasks.length);
    this.setState({daysOfMonthArray});
    this.showDayView(firstTasksOfMonth)
    return daysOfMonthArray;
  }

  nextMonth = (e) => {
    e.preventDefault();
    let nextMonth = (this.state.currentMonth).startOf('month').add(1, 'month');
    this.setState({ currentMonth: nextMonth});
    this.setTasksToDaysInMonth(this.setMonth(nextMonth), nextMonth, this.state.tasks);
    this.setState({ taskModals: null, dayViewShown: false});
    return nextMonth;
  }

  prevMonth = (e) => {
    e.preventDefault();
    let prevMonth = (this.state.currentMonth).startOf('month').subtract(1, 'month');
    this.setState({ currentMonth: prevMonth });
    this.setTasksToDaysInMonth(this.setMonth(prevMonth), prevMonth, this.state.tasks);
    this.setState({ taskModals: null, dayViewShown: false});
    return prevMonth;
  }

  showDayView(day) {
    if (!day) return false;
    this.state.selectedDay = day;
    if (day.tasks.length) {
      this.setState({todaysTasks: day.tasks});
      let date = Moment(day.tasks[0].due_at).format("M/D/YYYY");
      window.getDailyTaskData();
    }
  }

  componentWillMount() {
    const that = this;

    window.getDailyTaskData = function() {
      fetch(`/la/${encodeURIComponent(that.state.loanApplicationId)}/calendar?date=${Moment(that.state.selectedDay.date).format("M/D/YYYY")}&request_format=tasks`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          Accept: 'text/html',
          'Content-Type': 'text/html',
        }
      })
      .then(response => response.text())
      .then(data => {
        that.setState({ dayViewId: `day-menu-${that.state.selectedDay.dayIndex}`, dayViewShown: true, taskModals: data });
        $.R.Tasks.updateIncompleteTaskCount();
      });
    }

    window.getTaskData = function() {
      fetch(`${TASKS_API}=${encodeURIComponent(that.state.loanApplicationId)}`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      })
      .then(response => response.json())
      .then(data => {
        let tasks = data.filter(task => { return (task.completed_at === null) });
        that.setState(Object.assign({}, that.state, { tasks: tasks } ))
        that.setTasksToDaysInMonth(that.setMonth(that.state.currentMonth), that.state.currentMonth, tasks);
      });
    }
  }

  componentDidMount() {
    window.getTaskData();
  }

  render() {
    return (
      <div>
        <div className="c-calendar__wrap">
          <CalendarNavigation
            nextMonth={this.nextMonth}
            prevMonth={this.prevMonth}
            currentMonth={this.state.currentMonth}
            arrowLeftIcon={this.state.iconMap.cal_angle_left_icon}
            arrowRightIcon={this.state.iconMap.cal_angle_right_icon}
          />
          <CalendarMonth
            bellIcon={this.state.iconMap.cal_bell_icon}
            asteriskIcon={this.state.iconMap.cal_asterisk_icon}
            selectedDay={this.state.selectedDay}
            dayViewShown={this.state.dayViewShown}
            showDayView={this.showDayView.bind(this)}
            daysOfTheWeekAbbr={this.state.daysOfTheWeekAbbr}
            daysOfMonthArray={this.state.daysOfMonthArray}
          />
        </div>
        <CalendarDailyTasks dayViewId={this.state.dayViewId} dayViewShown={this.state.dayViewShown} selectedDay={this.state.selectedDay} todaysTasks={this.state.todaysTasks} taskModals={this.state.taskModals}/>
      </div>
    );
  }
}
